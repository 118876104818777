@tailwind base;
@tailwind components;
@tailwind utilities;
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.custom-text {
    position: relative;
    font-size: 1rem; /* Adjust the font size as needed */
    font-weight: bold; 
    font-family: 'Poppins', sans-serif; /* Use your desired font */
    color: purple; /* Set the text color to purple */
  }
  
  .custom-text::before {
    content: "DIGITAL ADS MEDIA";
    position: absolute;
    top: -10px;
    left: 0;
    width: 10rem !important;
    color: #800080; /* Set the shadow color to orange */
    z-index: -1;
    text-shadow: 2px 2px 0 rgba(255, 165, 0, 0.7); /* Adjust shadow properties */
  }


  #style-16::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
	background-color: #F5F5F5;
	border-radius: 10px;
}

body::-webkit-scrollbar
{
	width: 8px;
	background-color:  #F5F5F5!important;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: #F5F5F5 !important;
	background-image: -webkit-linear-gradient(top,
  #429EBD,
  #429EBD);
}


#progress{
  position: fixed;
  bottom: 20px;
  right: 10px;
  height: 70px;
  width: 70px;
  display: none;
  z-index: 5;
  place-items: center;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  cursor: pointer;
}

#progress-value{
  display: block;
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  background-color: #F5F5F5;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size: 30px;
  font-weight: 900;
  color: var(--fourth-primary);
}